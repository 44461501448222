
.examination-management {
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;

  .status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
